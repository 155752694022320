import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addAgentapi,
  getAgentListAPI,
  getAgentsAPI,
  updateAdminStatusApi,
  updateUserPasswordAPI,
} from "../../helpers/apis";

export const getAgents = createAsyncThunk(
  "Agents/getAgents",
  async (params) => {
    const response = await getAgentsAPI(params);
    return response.data;
  }
);

export const getAgentList = createAsyncThunk(
  "Agents/getAgentList",
  async () => {
    const response = await getAgentListAPI();
    return response.data;
  }
);

export const addAgent = createAsyncThunk("Agents/addAgent", async (payload) => {
  const response = await addAgentapi(payload);
  return response.data;
});

export const updateUserPassword = createAsyncThunk(
  "Users/updateUserPassword",
  async (data) => {
    const response = await updateUserPasswordAPI(data);
    return response.data;
  }
);

export const updateAdminStatus = createAsyncThunk(
  "Agents/updateAdminStatus",
  async ({ id, isActive }) => {
    const response = await updateAdminStatusApi(id, { isActive });
    return response.data;
  }
);
