import { createSlice } from "@reduxjs/toolkit";

import { getBankList } from "./actions";
import { toast } from "react-toastify";

const initialState = {
    banks: [],
};

const bankSlice = createSlice({
    name: "Banks",
    initialState,

    extraReducers: (builder) => {
        builder
            .addCase(getBankList.fulfilled, (state, action) => {
                state.banks = action.payload;
            })
            .addCase(getBankList.rejected, (state, action) => {
                toast.error(
                    action?.payload?.message || action.error?.message || "Unable to fetch, Please try again"
                );
            })
    },
});

export default bankSlice.reducer;
