import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getPayInHistoryAPI,
  getPayOutHistoryAPI,
  getManualOrderHistoryAPI,
  downloadMaualOrderReportApi,
  downloadPayInReportApi,
  downloadPayOutReportApi,
} from "../../helpers/apis";

export const getPayOutHistory = createAsyncThunk("Reports/getPayOutHistory", async (payload) => {
  const response = await getPayOutHistoryAPI(payload);
  return response.data;
});

export const getPayInHistory = createAsyncThunk("Reports/getPayInHistory", async (payload) => {
  const response = await getPayInHistoryAPI(payload);
  return response.data;
});

export const getManualOrderHistory = createAsyncThunk(
  "Reports/getManualOrderHistory",
  async (payload) => {
    const response = await getManualOrderHistoryAPI(payload);
    return response.data;
  }
);

export const downloadManualOrderReport = createAsyncThunk(
  "Report/downloadManualOrderReport",
  async (payload) => {
    const response = await downloadMaualOrderReportApi(payload);
    return response;
  }
);

export const downloadPayInReport = createAsyncThunk(
  "Report/downloadPayInReport",
  async (payload) => {
    const response = await downloadPayInReportApi(payload);
    return response;
  }
);

export const downloadPayOutReport = createAsyncThunk(
  "Report/downloadPayOutReport",
  async (payload) => {
    const response = await downloadPayOutReportApi(payload);
    return response;
  }
);
