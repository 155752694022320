import { io } from "socket.io-client";
import { api } from "../config";
// import { toast } from "react-toastify";

const socket = io(`${api.SOCKET_URL}`, {
  transports: ["websocket"],
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
});

socket.on("connect", () => {});

socket.on("disconnect", () => {
  //   toast.dismiss();
  //   toast.error("Disconnected from server");
});

socket.on("reconnect", (attemptNumber) => {});

socket.on("reconnect_attempt", (attemptNumber) => {});

socket.on("error", (error) => {});

export default socket;
