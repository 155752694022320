import { api } from "../config";

//LOGIN
export const AUTH_LOGIN = "/v1/auth/vendor/login";
export const AUTH_VERIFY2FA = "/v1/auth/verify2fa";
export const AUTH_REFERSH_TOKEN = "/v1/auth/refresh-token";
export const GET_IP =
  process.env.REACT_APP_LOCATION_URL ?? "https://ipapi.co/json/";

export const GET_USER_PROFILE = "/v1/users/me/vendor/profile";
export const ENABLE_2FA = "/v1/users/me/enable2FA";
export const VERIFY_2FA = "/v1/users/me/verify2FA";
export const DISABLE_2FA = "/v1/users/me/disable2FA";
export const RESET_USER_PASSWORD = "/v1/users/me/password/reset";

//USER
export const GET_USER_LIST = "/v1/users/user-list";

//PAYLOUT
export const GET_PAYOUT_REQ = "v1/payout";
export const CREATE_PAYOUT_REQ = "v1/payout";

export const POST_ADMIN_USER = (adminId) => `/v1/admins/${adminId}/admin-users`;
export const PATCH_USER_MODULES = "/v1/admins/user-module";
// export const PAYMENT_REPORT = `v1/reports/vendor/pay-in-out`;
export const PAYIN_HISTORY = `v1/reports/vendor/pay-in-history`;
export const PAYOUT_HISTORY = `v1/reports/vendor/pay-out-history`;
export const MANUAL_ORDER_HISTORY = `v1/reports/vendor/manual-order-history`;
export const DOWNLOAD_MANUAL_ORDER_REPORT = (type) =>
  `v1/reports/vendor/manual-order-history/download/${type}`;
export const DOWNLOAD_PAYIN_REPORT = (type) =>
  `v1/reports/pay-in-history/download/${type}`;
export const DOWNLOAD_PAYOUT_REPORT = (type) =>
  `v1/reports/pay-out-history/download/${type}`;

export const DASHBOARD_PAY_IN_OUT_STAT = `v1/dashboard/vendor/stat`;
export const PAY_IN_OUT_AVERAGE_TIME = `v1/dashboard/vendor/pay-in-out/average-time`;
export const SECURITY_KEY_URL = `/v1/users/me/vendor/security-key`;
export const TOKEN_URL = `/v1/users/me/vendor/token`;

export const GET_AGENTS = "/v1/agents";
export const PUT_AGENT = (id) => `v1/agents/${id}`;
export const GET_AGENT_LIST = "/v1/agents/agent-list";
export const ADD_NEW_AGENT = "/v1/agents";
export const UPDATE_AGENT_PASSWORD = "/V1/agents/update-password";

export const GET_MANUAL_ORDERS = "/v1/manual-order";
export const GET_MANUAL_ORDERS_COUNT = "/v1/manual-order/manual-order-count";

export const ADD_MANUAL_ORDER = "/v1/manual-order";
export const IMAGE_MANUAL_ORDER = "/v1/manual-order/payment-image";
export const ADD_PAY_IN_IMAGE_MANUAL_ORDER =
  "/v1/manual-order/pay-in-payment-image";
export const POST_SIGNED_IMAGE_URL = "v1/payment/file-url";
export const GET_BANK_LIST = "/v1/banks";
