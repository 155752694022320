import { createSlice } from "@reduxjs/toolkit";
import { createPayoutReq, getPayoutReq } from "./actions";
import { toast } from "react-toastify";

const initialState = {
    payoutReq: { items: [], totalRow: 0 },
}

const PayoutSlice = createSlice({
    name: "Payout",
    initialState,

    extraReducers: (builder) => {
        builder.addCase(getPayoutReq.fulfilled, (state, action) => {
            state.payoutReq.items = action.payload?.items;
            state.payoutReq.totalRow = action.payload?.total;
        });

        builder.addCase(createPayoutReq.fulfilled, (state, action) => {
            if(action.status == "success") toast.success("Payout request created successfully.")
        })
    }
});

export default PayoutSlice.reducer;