import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import thunk from 'redux-thunk';

export default configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    thunk: true,
    immutableCheck: true,
    serializableCheck: true,
  }).prepend(thunk),
});

// sagaMiddleware.run(rootSaga);
