import React, { useEffect, useState } from "react";
import { layoutModeTypes } from "../../Components/constants/layout";

const LightDark = ({ layoutMode, onChangeLayoutMode }) => {
  const [mode, setMode] = useState(
    layoutMode === layoutModeTypes["DARKMODE"]
      ? layoutModeTypes["LIGHTMODE"]
      : layoutModeTypes["DARKMODE"]
  );

  useEffect(() => {
    setMode(
      layoutMode === layoutModeTypes["DARKMODE"]
        ? layoutModeTypes["LIGHTMODE"]
        : layoutModeTypes["DARKMODE"]
    );
  }, [layoutMode]);

  return (
    <div className="ms-1 header-item d-none d-sm-flex">
      <button
        onClick={() => onChangeLayoutMode(mode)}
        type="button"
        className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle light-dark-mode"
      >
        <i className="bx bx-moon fs-22"></i>
      </button>
    </div>
  );
};

export default LightDark;
