import React from "react";
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";

const Loader = () => {
  const { loader } = useSelector((state) => ({
    loader: state.Layout.loader,
  }));

  return (
    <React.Fragment>
      {loader ? (
        <div className="loader-wrapper">
          <div className="loader ">
            <Spinner color="primary" className="top-50 start-50 position-absolute">
              Loading...
            </Spinner>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default Loader;
