import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAveragePayInOutTimeAPI,
  getDashboardPayInOutStatAPI,
} from "../../helpers/apis";

export const getDashboardPayInOutStat = createAsyncThunk(
  "Dashboard/getDashboardPayInOutStat",
  async (payload) => {
    const response = await getDashboardPayInOutStatAPI(payload);

    return response.data;
  }
);

export const getAveragePayInOutTime = createAsyncThunk(
  "Dashboard/getAveragePayInOutTime",
  async (params) => {
    const response = await getAveragePayInOutTimeAPI(params);
    return response.data;
  }
);
