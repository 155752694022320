import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ENUM_USER_BY_PAYMENT_TYPE } from "../helpers/constants";
import {
  ENUM_ADMIN_TYPE,
  ENUM_USER_TYPE,
} from "../Components/constants/constants";
import { getManualOrderCount } from "../store/manualOrder/actions";
import { getLoggedinUser } from "../helpers/api_helper";

const Navdata = () => {
  const history = useNavigate();
  const { payoutUser, userData, manualOrderCount } = useSelector((state) => ({
    payoutUser: state.User.payoutUser,
    userData: state.User.user,
    manualOrderCount: state.ManualOrder.manualOrderCount,
  }));
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isAgents, setIsAgents] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const loggedInUser = getLoggedinUser();
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      loggedInUser &&
      loggedInUser.userType == ENUM_USER_BY_PAYMENT_TYPE.MANUAL
    ) {
      dispatch(getManualOrderCount());
    }
  }, []);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") setIsDashboard(false);
    if (iscurrentState !== "Reports") setIsReports(false);
    if (iscurrentState !== "Agents") setIsAgents(false);

    // if (iscurrentState === "Widgets") {
    //   history("/widgets");
    //   document.body.classList.add("twocolumn-panel");
    // }
  }, [history, iscurrentState, isDashboard, isReports]);

  let menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Dashboard");
      },
    },
    {
      id: "payout-request",
      label: "Payout",
      icon: "ri-money-dollar-circle-line",
      link: "/payout",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Payout");
      },
    },
    {
      id: "manualOrder",
      label: `Manual Order${
        manualOrderCount ? "(" + manualOrderCount + ")" : ""
      }`,
      icon: "ri-luggage-cart-line",
      link: "/manual-order",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("ManualOrder");
      },
    },
    {
      id: "reports",
      label: "Reports",
      icon: "ri-pages-line",
      link: "/reports",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "pay-in-history",
          label: "Pay-in History",
          link: "/reports/pay-in-history",
          parentId: "reports",
        },
        {
          id: "pay-out-history",
          label: "Pay-out History",
          link: "/reports/pay-out-history",
          parentId: "reports",
        },
        {
          id: "manual-order",
          label: "Manual Order History",
          link: "/reports/manual-order-history",
          parentId: "reportsagent",
        },
      ],
    },
    {
      id: "reportsagent",
      label: "Reports",
      icon: "ri-pages-line",
      link: "/reports",
      stateVariables: isReports,
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Reports");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "manual-order",
          label: "Manual Order History",
          link: "/reports/manual-order-history",
          parentId: "reportsagent",
        },
      ],
    },
    {
      id: "agents",
      label: "Agents",
      icon: "ri-user-2-line",
      link: "/agents",
      click: function (e) {
        e.preventDefault();
        setIscurrentState("Agents");
      },
    },
  ];

  if (!payoutUser)
    menuItems = menuItems.filter((ele) => ele.id !== "payout-request");

  if (
    userData &&
    Object.keys?.(userData)?.length &&
    userData?.userType === ENUM_USER_BY_PAYMENT_TYPE.ONLINE
  ) {
    menuItems = menuItems.map((ele) => {
      if (ele?.id == "reports") {
        return {
          ...ele,
          subItems: ele?.subItems?.filter(
            (item) => item?.id !== "manual-order"
          ),
        };
      }
      return ele;
    });

    menuItems = menuItems.filter((ele) => ele.id !== "manualOrder");
  } else if (
    userData &&
    Object.keys?.(userData)?.length &&
    userData?.userType === ENUM_USER_BY_PAYMENT_TYPE.MANUAL
  ) {
    menuItems = menuItems.filter((ele) => ele.id !== "payout-request");

    menuItems = menuItems.map((ele) => {
      if (ele?.id == "reports") {
        return {
          ...ele,
          subItems: ele?.subItems?.filter((item) => item?.id == "manual-order"),
        };
      }
      return ele;
    });
  }
  if (
    userData &&
    Object.keys?.(userData)?.length &&
    userData.type == ENUM_ADMIN_TYPE.AGENT
  ) {
    menuItems = menuItems.filter(
      (ele) =>
        ele.id == "manualOrder" ||
        ele.id == "reportsagent" ||
        ele.id == "Dashboard"
    );
  }

  if (
    userData &&
    Object.keys?.(userData)?.length &&
    userData.type == ENUM_ADMIN_TYPE.VENDOR
  ) {
    menuItems = menuItems.filter((ele) => ele.id != "reportsagent");
  }
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
