import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUserProfile as getUserProfileAPI,
  enable2FA as enable2FAAPI,
  verify2FA as verify2FAAPI,
  disable2FA as disable2FAAPI,
  resetPassword as resetPasswordApi,
  fetchSecurityKey as fetchSecurityKeyAPI,
  fetchToken as fetchTokenAPI,
} from "../../helpers/apis";

export const clearUser = createAsyncThunk("user/clearUser", async () => {
  return true
});

// 2FA
export const enable2FA = createAsyncThunk("user/enable2FA", async () => {
  const response = await enable2FAAPI();
  return response.data;
});
export const verify2FA = createAsyncThunk("user/verify2FA", async (code) => {
  const response = await verify2FAAPI(code);
  return response.data;
});

export const disable2FA = createAsyncThunk("user/disable2FA", (code) => {
  const response = disable2FAAPI(code);
  return response;
});

export const resetVerify2FA = createAsyncThunk("user/resetVerify2FA", () => {
  return true;
});

export const editProfile = createAsyncThunk("user/editProfile", (user) => {
  return true;
});

export const getUserProfile = createAsyncThunk(
  "user/getUserProfile",
  async () => {
    const response = await getUserProfileAPI();
    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data) => {
    const response = await resetPasswordApi(data);
    return response?.data;
  }
);

export const getSecurityKey = createAsyncThunk(
  "user/getSecurityKey",
  async () => {
    const response = await fetchSecurityKeyAPI();
    return response.data;
  }
);

export const getToken = createAsyncThunk("user/getToken", async () => {
  const response = await fetchTokenAPI();
  return response.data;
});
