import { createSlice } from "@reduxjs/toolkit";

import {
  clearUser,
  disable2FA,
  enable2FA,
  getUserProfile,
  resetPassword,
  resetVerify2FA,
  verify2FA,
} from "./actions";
import { toast } from "react-toastify";

const initialState = {
  user: {},
  type: "",
  payoutUser: false,
  isverify2FA: false,
  modules: {},
  authRoutes: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setMenuState: (state, action) => {
      for (const k in state.menuState) {
        if (Object.hasOwnProperty.call(state.menuState, k)) {
          state.menuState[k] = false;
        }
      }
      state.menuState[action.payload] = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.user = action.payload;
      state.type = action.payload?.type;
      state.payoutUser = action.payload?.payoutUser ?? false;
      state.modules = action.payload?.role?.modules ?? [];
    });
    builder.addCase(clearUser.fulfilled, (state, action) => {
      state.user = {};
      state.type = '';
      state.payoutUser = false;
      state.isverify2FA = false;
      state.modules = {};
      state.authRoutes = {};
    })
    builder
      .addCase(verify2FA.fulfilled, (state, action) => {
        state.user.enable2FA = true;
        state.isverify2FA = true;
      })
      .addCase(verify2FA.rejected, (state, action) => {
        state.error = action.error;
        state.isverify2FA = false;
      });

    builder
      .addCase(disable2FA.fulfilled, (state, action) => {
        state.user.enable2FA = false;
        state.isverify2FA = true;
      })
      .addCase(disable2FA.rejected, (state, action) => {
        state.error = action.payload;
        state.isverify2FA = false;
      });

    builder
      .addCase(enable2FA.fulfilled, (state, action) => {
        state.secret = action.payload;
      })
      .addCase(resetVerify2FA.fulfilled, (state, action) => {
        state.isverify2FA = false;
      });

    builder
      .addCase(resetPassword.fulfilled, (state, action) => {
        toast.success(action.payload.message);
        state.user = action.payload.data;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        toast.error(
          action?.payload?.message || action.error?.message || "Unable to change the password!"
        );
      });
  },
});

export const { setMenuState } = userSlice.actions;

export default userSlice.reducer;
