import { createSlice } from "@reduxjs/toolkit";

import {
  getManualOrders,
  addManualOrder,
  addPayInImageRequest,
  getManualOrderCount,
} from "./actions";
import { toast } from "react-toastify";

const initialState = {
  manualOrders: [],
  totalRows: 0,
  manualOrderCount: 0,
};

const manualOrderSlice = createSlice({
  name: "ManualOrder",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getManualOrders.fulfilled, (state, action) => {
        state.manualOrders = action.payload.items;
        state.totalRows = action.payload.total;
      })
      .addCase(getManualOrders.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to fetch, Please try again"
        );
      })
      .addCase(getManualOrderCount.fulfilled, (state, action) => {
        state.manualOrderCount = action.payload?.total || 0;
      })
      .addCase(addManualOrder.fulfilled, (state, action) => {
        toast.success("Manual Order Created");
      })
      .addCase(addManualOrder.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to create manual order"
        );
      })
      .addCase(addPayInImageRequest.fulfilled, (state, action) => {
        toast.success("Manual Order Created");
      })
      .addCase(addPayInImageRequest.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to create manual order"
        );
      });
  },
});

export default manualOrderSlice.reducer;
