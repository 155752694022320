import React, { useEffect, useLayoutEffect } from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";
import { encrypt } from "./helpers/methods/encryptDecrypt";
import { updateHeader } from "./helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { ClientJS } from "clientjs";
import { api } from "./config";
import { publicIpv4 } from "public-ip";
import socket from "./sockets/socket";

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const client = new ClientJS();

  const { user } = useSelector((state) => ({
    user: state.User.user,
  }));

  useLayoutEffect(() => {
    const geoData = localStorage.getItem("_GE_IED_");
    if (!geoData) {
      const browser = client.getBrowser();
      const browserVersion = client.getBrowserVersion();
      const fingerprint = client.getFingerprint();
      const isMobile = client.isMobile();

      publicIpv4({
        fallbackUrls: [api.IP_URL, api.IP2_URL],
      }).then(async (e) => {
        const data = {
          ip: e,
          browser,
          browserVersion,
          fingerprint,
          deviceType: isMobile ? "MOBILE" : "WEB",
        };
        const geoData = encrypt(data);

        await localStorage.setItem("_GE_IED_", geoData);
        updateHeader(data);
      });
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      if (localStorage.getItem("authUser")) {
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const handleLogoutUser = (data) => {
      if (!data.isActive) {
        navigate("/logout")
      }
      if(data._id == user?._id) {
        navigate("/logout");
      }
    };

    socket.on("logout-user", handleLogoutUser);

    return () => {
      socket.off("logout-user", handleLogoutUser);
    };
  }, [user]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
