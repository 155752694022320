import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import User from "./user/reducer";
import Users from "./users/reducer";
import Reports from "./reports/reducer";
import Dashboard from "./dashboard/reducer";
import Payout from "./payout/reducer";
import Agents from "./agents/reducer";
import ManualOrder from "./manualOrder/reducer";
import Banks from "./banks/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  User,
  Users,
  Payout,
  Reports,
  Dashboard,
  Agents,
  ManualOrder,
  Banks
});

export default rootReducer;
