import moment from "moment-timezone";

export const DATE_RANGE = {
  today: "Today",
  yesterday: "Yesterday",
  last7Days: "Last 7 Days",
  thisMonth: "This Month",
  lastMonth: "Last Month",
  customDate: "Custom Date",
};

export const dropdownDateMapping = {
  [DATE_RANGE.today]: (() => {
    const today = new Date();
    const startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );

    return { startDate, endDate };
  })(),
  [DATE_RANGE.yesterday]: (() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const startDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate()
    );
    const endDate = new Date(
      yesterday.getFullYear(),
      yesterday.getMonth(),
      yesterday.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.last7Days]: (() => {
    const today = new Date();
    const last7Days = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
    const startDate = new Date(
      last7Days.getFullYear(),
      last7Days.getMonth(),
      last7Days.getDate()
    );
    const endDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.thisMonth]: (() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );
    const startDate = new Date(
      firstDayOfMonth.getFullYear(),
      firstDayOfMonth.getMonth(),
      firstDayOfMonth.getDate()
    );
    const endDate = new Date(
      lastDayOfMonth.getFullYear(),
      lastDayOfMonth.getMonth(),
      lastDayOfMonth.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),
  [DATE_RANGE.lastMonth]: (() => {
    const today = new Date();
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1);
    const lastDayOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth() + 1,
      0
    );
    const firstDayOfLastMonth = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      1
    );
    const startDate = new Date(
      firstDayOfLastMonth.getFullYear(),
      firstDayOfLastMonth.getMonth(),
      firstDayOfLastMonth.getDate()
    );
    const endDate = new Date(
      lastDayOfLastMonth.getFullYear(),
      lastDayOfLastMonth.getMonth(),
      lastDayOfLastMonth.getDate(),
      23,
      59,
      59,
      999
    );
    return { startDate, endDate };
  })(),

  [DATE_RANGE.customDate]: (start, end) => {
    const startDate = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate(),
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      end.getFullYear(),
      end.getMonth(),
      end.getDate(),
      23,
      59,
      59,
      999
    );

    return { startDate, endDate };
  },
};

export const formatDateRelativeToNow = (inputDateStr) => {
  let inputDateTemp =
    typeof inputDateStr === "string" && !isNaN(inputDateStr)
      ? moment.unix(inputDateStr)
      : inputDateStr;
  const currentTimezone = moment.tz.guess();
  const inputDate = moment.tz(inputDateTemp, currentTimezone);
  const currentDate = moment.tz(currentTimezone);

  // Compare the dates.
  if (inputDate.isSame(currentDate, "day")) {
    // If it's today.
    return { date: "Today", time: inputDate.format("hh:mm:ss A") };
  } else if (inputDate.isSame(currentDate.clone().add(1, "days"), "day")) {
    // If it's tomorrow.
    return { date: "Tomorrow", time: inputDate.format("hh:mm:ss A") };
  } else {
    // Otherwise, format as "DD-MM HH:mm".
    return {
      date: inputDate.format("DD-MM-YYYY"),
      time: inputDate.format("hh:mm:ss A"),
    };
  }
};

export const TRANSACTION_STATUS = {
  PENDING: 0,
  APPROVED: 1,
  IN_PROGRESS: 2,
  DECLINED: 3,
  FAILED: 4,
};

export const ENUM_USER_BY_PAYMENT_TYPE = {
  MANUAL: "MANUAL",
  ONLINE: "ONLINE",
};

export const PAYMENT_MODE = {
  MANUAL: "MANUAL",
  ONLINE: "ONLINE",
};
