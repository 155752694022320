import { createSlice } from "@reduxjs/toolkit";

import {
  addAgent,
  getAgentList,
  getAgents,
  getAgentsnts,
  updateUserPassword,
} from "./actions";
import { toast } from "react-toastify";

const initialState = {
  agents: [],
  agentList: [],
  totalRows: 0,
};

const agentSlice = createSlice({
  name: "Agents",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAgents.fulfilled, (state, action) => {
        state.agents = action.payload.items;
        state.totalRows = action.payload.total;
      })
      .addCase(getAgents.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to fetch, Please try again"
        );
      })
      .addCase(addAgent.fulfilled, (state, action) => {
        toast.success("Agent Created");
      })
      .addCase(addAgent.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Unable to create user"
        );
      })
      .addCase(getAgentList.fulfilled, (state, action) => {
        state.agentList = action.payload;
      })
      .addCase(updateUserPassword.fulfilled, (state, action) => {
        toast.success("Agent password updated");
      })
      .addCase(updateUserPassword.rejected, (state, action) => {
        toast.error(
          action?.payload?.message ||
            action.error?.message ||
            "Failed to updated agent password"
        );
      });
  },
});

export default agentSlice.reducer;
