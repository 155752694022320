import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getPayoutReq as getPayoutReqAPI,
    createPayoutReq as createPayoutReqAPI,
} from "../../helpers/apis";

export const getPayoutReq = createAsyncThunk("user/getPayoutReq", async (payload) => {
  const response = await getPayoutReqAPI(payload);
  return response.data;
});

export const createPayoutReq = createAsyncThunk("user/createPayoutReq", async (payload) => {
  const response = await createPayoutReqAPI(payload);
  return response.data;
});


