import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getBankList as getBankListAPI,
} from "../../helpers/apis";
export const getBankList = createAsyncThunk(
    "market/getPaymentBankList",
    async (payload) => {
        const response = await getBankListAPI(payload);
        return response.data;
    }
);
