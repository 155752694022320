module.exports = {
  api: {
    APP_NAME: process.env.REACT_APP_NAME,
    API_URL: process.env.REACT_APP_API_URL,
    FRONT_API_URL: process.env.REACT_APP_FRONT_API_URL,
    BASE_URL: process.env.PUBLIC_URL,
    SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
    IP_URL: process.env.REACT_APP_LOCATION_URL,
    IP2_URL: process.env.REACT_APP_LOCATION2_UR,
  },
};
