import { APIHelper } from "./api_helper";
import * as url from "./url_helper";

//Post
export const postLogin = (data) => APIHelper.post(url.AUTH_LOGIN, data);
export const loginVerify2FA = (data) =>
  APIHelper.post(url.AUTH_VERIFY2FA, data);
export const postRefreshToken = (data) =>
  APIHelper.post(url.AUTH_REFERSH_TOKEN, data);
export const getUserProfile = () => APIHelper.get(url.GET_USER_PROFILE);
export const resetPassword = (data) =>
  APIHelper.patch(url.RESET_USER_PASSWORD, data);

export const enable2FA = () => APIHelper.get(url.ENABLE_2FA);
export const verify2FA = (code) => APIHelper.post(url.VERIFY_2FA, code);
export const disable2FA = (code) => APIHelper.post(url.DISABLE_2FA, code);

// Users
export const getUserList = (params) => APIHelper.get(url.GET_USER_LIST, params);

//Payout
export const getPayoutReq = (params) =>
  APIHelper.get(url.GET_PAYOUT_REQ, params);
export const createPayoutReq = (params) =>
  APIHelper.post(url.CREATE_PAYOUT_REQ, params);

//Reports
export const getPayOutHistoryAPI = (payload) =>
  APIHelper.post(url.PAYOUT_HISTORY, payload);
export const getPayInHistoryAPI = (payload) =>
  APIHelper.post(url.PAYIN_HISTORY, payload);
export const getManualOrderHistoryAPI = (payload) =>
  APIHelper.post(url.MANUAL_ORDER_HISTORY, payload);
export const downloadMaualOrderReportApi = ({ type, data }) =>
  APIHelper.post(url.DOWNLOAD_MANUAL_ORDER_REPORT(type), data);
export const downloadPayInReportApi = ({ type, data }) =>
  APIHelper.post(url.DOWNLOAD_PAYIN_REPORT(type), data);
export const downloadPayOutReportApi = ({ type, data }) =>
  APIHelper.post(url.DOWNLOAD_PAYOUT_REPORT(type), data);

//Dashboard
export const getDashboardPayInOutStatAPI = (payload) =>
  APIHelper.post(url.DASHBOARD_PAY_IN_OUT_STAT, payload);
export const getAveragePayInOutTimeAPI = (params) => {
  return APIHelper.post(url.PAY_IN_OUT_AVERAGE_TIME, params);
};
//Profile
export const fetchSecurityKey = (payload) =>
  APIHelper.get(url.SECURITY_KEY_URL, payload);

export const fetchToken = (payload) => APIHelper.get(url.TOKEN_URL, payload);

// Agents
export const getAgentsAPI = (params) => APIHelper.get(url.GET_AGENTS, params);
export const updateAdminStatusApi = (id, payload) =>
  APIHelper.put(url.PUT_AGENT(id), payload);
export const getAgentListAPI = (params) =>
  APIHelper.get(url.GET_AGENT_LIST, params);
export const addAgentapi = (payload) =>
  APIHelper.post(url.ADD_NEW_AGENT, payload);
export const updateUserPasswordAPI = (payload) =>
  APIHelper.update(url.UPDATE_AGENT_PASSWORD, payload);
// Manual Order
export const getManualOrderAPI = (params) =>
  APIHelper.get(url.GET_MANUAL_ORDERS, params);
export const getManualOrderCountAPI = (params) =>
  APIHelper.get(url.GET_MANUAL_ORDERS_COUNT, params);
export const addManualOrderAPI = (payload) =>
  APIHelper.post(url.ADD_MANUAL_ORDER, payload);

export const processImageRequestAPI = (payload) =>
  APIHelper.update(url.IMAGE_MANUAL_ORDER, payload);

export const addPayInImageRequestAPI = (payload) =>
  APIHelper.post(url.ADD_PAY_IN_IMAGE_MANUAL_ORDER, payload);

export const getSignedImageURLAPI = (payload) =>
  APIHelper.post(url.POST_SIGNED_IMAGE_URL, payload);

export const getBankList = (payload) =>
  APIHelper.get(url.GET_BANK_LIST, payload);
