import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addManualOrderAPI,
  addPayInImageRequestAPI,
  getManualOrderAPI,
  getManualOrderCountAPI,
  getSignedImageURLAPI,
  processImageRequestAPI,
} from "../../helpers/apis";

export const getManualOrders = createAsyncThunk(
  "manualOrder/getManualOrders",
  async (params) => {
    const response = await getManualOrderAPI(params);
    return response.data;
  }
);

export const getManualOrderCount = createAsyncThunk(
  "manualOrder/getManualOrderCount",
  async (payload) => {
    const response = await getManualOrderCountAPI(payload);
    return response.data;
  }
);

export const addManualOrder = createAsyncThunk(
  "manualOrder/addManualOrder",
  async (payload) => {
    const response = await addManualOrderAPI(payload);
    return response.data;
  }
);

export const getSignedImageURL = createAsyncThunk(
  "Payments/getSignedImageURL",
  async (payload) => {
    const response = await getSignedImageURLAPI(payload);
    return response.data;
  }
);

export const processImageRequest = createAsyncThunk(
  "manualOrder/processImageRequest",
  async (payload) => {
    const response = await processImageRequestAPI(payload);
    return response.data;
  }
);

export const addPayInImageRequest = createAsyncThunk(
  "manualOrder/addPayInImageRequest",
  async (payload) => {
    const response = await addPayInImageRequestAPI(payload);
    return response.data;
  }
);
