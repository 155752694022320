import { createSlice } from "@reduxjs/toolkit";
import { loginUser, resetLoginFlag, loginVerify2FA } from "./actions";

const initialState = {
  errorMsg: "",
  loading: false,
  error: false,
  user: {},
  isUserLogout: true,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logoutUserSuccess: (state) => {
      localStorage.removeItem("authUser");
      state.user = {};
      state.isUserLogout = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.isUserLogout = false;
        state.errorMsg = action.error.message;
      });
    builder
      .addCase(loginVerify2FA.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(loginVerify2FA.fulfilled, (state, action) => {
        console.log("loginVerify2FA state, action", action);
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginVerify2FA.rejected, (state, action) => {
        state.loading = false;
        state.error = true;
        state.isUserLogout = false;
        state.errorMsg = action.error.message;
      });
    builder.addCase(resetLoginFlag.fulfilled, (state, action) => {
      state.user = {};
      state.loading = false;
      state.error = false;
      state.isUserLogout = true;
      state.errorMsg = "";
    });
  },
});
export const { logoutUserSuccess } = loginSlice.actions;
export default loginSlice.reducer;
