import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import withRouter from "../Components/Common/withRouter";

//import Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import Loader from "../Components/Common/Loader";

//import actions
import { changeLayoutMode } from "../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { ENUM_ROLE_TYPE } from "../Components/constants/constants";

const Layout = (props) => {
  const [headerClass, setHeaderClass] = useState("");
  const dispatch = useDispatch();

  const { layoutModeType, user, authRoutes } = useSelector((state) => ({
    layoutModeType: state.Layout.layoutModeType,
    user: state.User.user,
    authRoutes: state.User.authRoutes,
  }));

  const isSubadmin = user?.role?.type === ENUM_ROLE_TYPE.SUBADMIN;
  let path = "";
  if (isSubadmin) {
    path = props.routePath?.split("/:")?.[0];
  }

  /*
    layout settings
    */
  useEffect(() => {
    if (layoutModeType) {
      dispatch(changeLayoutMode(layoutModeType));
    }
  }, [layoutModeType, dispatch]);
  /*
    call dark/light mode
    */
  const onChangeLayoutMode = (value) => {
    if (changeLayoutMode) {
      dispatch(changeLayoutMode(value));
    }
  };

  // class add remove in header
  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  function scrollNavigation() {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > 50) {
      setHeaderClass("topbar-shadow");
    } else {
      setHeaderClass("");
    }
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (isSubadmin && !authRoutes?.[path] && Object.keys(authRoutes)?.length) {
      navigate("/dashboard");
    }
  }, [isSubadmin, authRoutes, path]);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header
          headerClass={headerClass}
          layoutModeType={layoutModeType}
          onChangeLayoutMode={onChangeLayoutMode}
        />
        <Sidebar layoutType="horizontal" />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Loader />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
};

export default withRouter(Layout);
