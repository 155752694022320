import React, { lazy } from "react";
import { Navigate } from "react-router-dom";

// const PayInList = lazy(() => import("../pages/Payment/PayIn"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const UserProfile = lazy(() => import("../pages/Profile"));
const BasicTwosVerify = lazy(() =>
  import("../pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify")
);
const Basic404 = lazy(() => import("../pages/AuthenticationInner/Errors/Basic404"));
const Error500 = lazy(() => import("../pages/AuthenticationInner/Errors/Error500"));
const BasicLockScreen = lazy(() => import("../pages/AuthenticationInner/LockScreen/BasicLockScr"));

//login
const Login = lazy(() => import("../pages/Auth/Login"));
const Logout = lazy(() => import("../pages/Auth/Logout"));
const Payout = lazy(() => import("../pages/Payout"));
const PayInHistory = lazy(() => import("../pages/Reports/PaymentReports/PayInHistory"));
const PayOutHistory = lazy(() => import("../pages/Reports/PaymentReports/PayOutHistory"));
const ManualOrder = lazy(() => import("../pages/ManualOrder"));
const AgentList = lazy(() => import("../pages/Agent"));
const ManualOrderHistory = lazy(() => import("../pages/Reports/ManualOrderHistory"));
const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },
  { path: "/payout", component: <Payout /> },
  { path: "/reports/pay-out-history", component: <PayOutHistory /> },
  { path: "/reports/pay-in-history", component: <PayInHistory /> },
  { path: "/reports/manual-order-history", component: <ManualOrderHistory /> },
  { path: "/agents", component: <AgentList /> },
  { path: "/manual-order", component: <ManualOrder /> },

  //User Profile
  { path: "/users/profile", component: <UserProfile page="profile" /> },
  { path: "/users/setting", component: <UserProfile page="setting" /> },
  {
    path: "/users/change-password",
    component: <UserProfile page="change-password" />,
  },
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  //AuthenticationInner pages
  { path: "/auth-lockscreen-basic", component: <BasicLockScreen /> },
  { path: "/verify/twostep", component: <BasicTwosVerify /> },
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/auth-500", component: <Error500 /> },
];

export { authProtectedRoutes, publicRoutes };
