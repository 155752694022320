import { createSlice } from "@reduxjs/toolkit";

import { getUserList } from "./actions";

const initialState = {
  users: [],
  userList: [],
  totalRows: 0,
};

const userSlice = createSlice({
  name: "Users",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getUserList.fulfilled, (state, action) => {
        state.userList = action.payload;
      })
  },
});

export default userSlice.reducer;
